import { tushopLocation } from "src/utils/utils";
import * as yup from "yup";

export const partnerForm = {
  publish: false,
  userData: {
    userId: null,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  },
  partnerData: {
    partnerId: null,
    name: "",
    partnerTypeId: null,
    partnerLink: "https://tushop.io/seller/",
    description: null,
    customerCareNumber: "",
    logo: null,
    coverPhoto: null,
    partnerImages: []
  },
  brandIds: [],
  productVariantIds: [],
  deliveryData: {
    deliveredByPartner: true,
    deliveryRadius: 0,
    freeDeliveryThreshold: null,
    noDeliveryDays: [],
    deliveryMethodId: null,
    deliveryFees: Array(5).fill({ distance: null, fee: null }),
    location: tushopLocation
  }
};

const deliverySchema = yup.object().shape({
  deliveredByPartner: yup.boolean().required("Delivered by partner is required"),

  deliveryRadius: yup
    .number()
    .transform((value, originalValue) => {
      // If the original value is an empty string or invalid, return null
      return isNaN(originalValue) || originalValue === "" ? null : value;
    })
    .nullable()
    .when("deliveredByPartner", {
      is: true,
      then: (schema) =>
        schema
          .typeError("Delivery radius must be a valid number") // <- Handles NaN & wrong types
          .required("Delivery radius is required")
          .moreThan(0, "Delivery radius must be greater than 0"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
  freeDeliveryThreshold: yup.number().nullable(),

  noDeliveryDays: yup
    .array()
    .of(yup.number())
    .nullable()
    .when("$deliveredByPartner", {
      is: true,
      then: (schema) => schema.required("No delivery days are required"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),

  deliveryMethodId: yup
    .number()
    .nullable()
    .when("deliveredByPartner", {
      is: true,
      then: (schema) => schema.required("Select a delivery method"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),

  deliveryFees: yup
    .array()
    .of(
      yup.object().shape({
        distance: yup
          .number()
          .nullable()
          .when("$deliveredByPartner", {
            is: true,
            then: (schema) => schema.required("Distance is required"),
            otherwise: (schema) => schema.nullable().notRequired()
          }),
        fee: yup
          .number()
          .nullable()
          .when("$deliveredByPartner", {
            is: true,
            then: (schema) => schema.required("Fee is required"),
            otherwise: (schema) => schema.nullable().notRequired()
          })
      })
    )
    .nullable()
    .when("deliveredByPartner", {
      is: true,
      then: (schema) =>
        schema
          .required("Delivery fees are required")
          .test(
            "at-least-one-non-null-fee",
            "At least one delivery fee must be filled",
            function (fees) {
              if (!fees || fees.length === 0) {
                return false; // Fail if fees are empty or undefined
              }

              const hasAtLeastOneValidFee = fees.some(
                (fee) =>
                  fee?.distance !== null &&
                  fee?.distance !== undefined &&
                  fee?.fee !== null &&
                  fee?.fee !== undefined
              );

              return hasAtLeastOneValidFee;
            }
          ),
      otherwise: (schema) => schema.nullable().notRequired()
    })
});

const userSchema = yup.object().shape({
  usedrId: yup.number().nullable(),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone is required")
});

const partnerSchema = yup.object().shape({
  name: yup.string().required("Partner name is required"),
  logo: yup.mixed().required("Partner logo is required"),
  coverPhoto: yup.mixed().required("Partner cover photo is required"),
  partnerTypeId: yup.number().required("Partner type is required"),
  partnerLink: yup.string().required("Partner link is required"),
  description: yup.string().required("Partner description is required"),
  customerCareNumber: yup.string().required("Customer care number is required"),
  partnerImages: yup
    .array()
    .nullable()
    .required("Partner images are required")
    .min(1, "Please upload at least one image")
    .of(yup.mixed().required("Each partner image is required"))
});

export const partnerFormSchema = yup.object().shape({
  userData: userSchema,
  partnerData: partnerSchema,
  brandIds: yup
    .array()
    .min(1, "At least one brand is required")
    .of(yup.number().required("Brand is required")),
  productVariantIds: yup
    .array()
    .of(yup.number().required("Products Ids should be numbers"))
    .default([]),
  deliveryData: deliverySchema
});
