// src/components/PopupModal.js
import { Close } from "@mui/icons-material";
import React from "react";
import usePopupStore from "src/state/popupStore";

function PopupModal() {
  const {
    isOpen,
    message,
    title,
    confirmPopup,
    cancelPopup,
    closePopup,
    buttonName = "Close"
  } = usePopupStore();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="flex flex-col bg-white rounded-lg">
        <div className="px-[16px] bg-[#F2F2F2] flex justify-between items-center rounded-tl-lg rounded-tr-lg py-[16px]">
          {title ? <h1 className="text-[18px] font-bold">{title}</h1> : <span></span>}
          <Close className="text-lg cursor-pointer" onClick={closePopup} />
        </div>
        <div className=" rounded-lg shadow-lg p-6 min-w-[500px] max-w-[90%]">
          <p className=" text-lg mb-4">{message}</p>
          <div className="flex justify-center gap-4">
            <button className="px-4 py-2 bg-[#EF7B22] rounded  text-white" onClick={confirmPopup}>
              {buttonName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupModal;
