import { createContext, useContext, useReducer } from "react";

// Initial state
const initialState = {
  phoneNumber: "",
  firstName: "",
  lastName: "",
  deliveryAddress: {
    houseNumber: "",
    street: "",
    landMark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    googlePin: "",
    latitude: "",
    longitude: ""
  },
  pickupAddress: {
    houseNumber: "Avenue Park 1",
    street: "Avenue Park 1",
    landMark: "Avenue Park 1",
    city: "Nairobi",
    state: "Nairobi COunty",
    country: "Kenya",
    pincode: "",
    googlePin: "-1.3141587690510088, 36.89297653658592",
    latitude: "-1.3141587690510088",
    longitude: "36.89297653658592"
  },
  cartProducts: [],
  orderData: {
    deliveryInstruction: null,
    pickupContactNumber: process.env.REACT_APP_DEFAULT_PICKUP_CONTACT_NUMBER || "",
    pickupInstruction: null
  },
  deliveryDate: null
};

// Action types
const UPDATE_PICKUP = "UPDATE_PICKUP";
const UPDATE_DROPOFF = "UPDATE_DROPOFF";
const ADD_TO_CART = "ADD_TO_CART";
const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA";
const UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME";
const UPDATE_LAST_NAME = "UPDATE_LAST_NAME";
const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER";
const UPDATE_DELIVERY_DATE = "UPDATE_DELIVERY_DATE";

// Reducer
const deliveryRequestReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PICKUP:
      return {
        ...state,
        pickupAddress: { ...state.pickupAddress, ...action.payload }
      };
    case UPDATE_DROPOFF:
      return {
        ...state,
        deliveryAddress: { ...state.deliveryAddress, ...action.payload }
      };
    case ADD_TO_CART:
      return {
        ...state,
        cartProducts: action.payload
      };
    case UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload["firstName"]
      };
    case UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.payload["lastName"]
      };
    case UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload["phoneNumber"]
      };
    case UPDATE_ORDER_DATA:
      return {
        ...state,
        orderData: { ...state.orderData, ...action.payload }
      };
    case UPDATE_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload["deliveryDate"]
      };
    default:
      return state;
  }
};

// Create context
const DeliveryRequestContext = createContext();

// Provider component
export const DeliveryRequestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(deliveryRequestReducer, initialState);

  return <DeliveryRequestContext.Provider value={{ state, dispatch }}>{children}</DeliveryRequestContext.Provider>;
};

// Custom hook for using the context
export const useDeliveryRequest = () => {
  return useContext(DeliveryRequestContext);
};
