import React, { useState } from "react";
import { ContentCopy, Check } from "@mui/icons-material";

/**
 *
 * @param {label} label - The label text for the generated links.
 * @param {required} required - Whether the generated links are required.
 * @param {link} link - The link to be copied.
 * @returns A labeled generated links with the specified label, required status, and link.
 */

function GeneratedLinks({ label, required = false, link }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    e.preventDefault();
    if (!link) return;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000); // Reset after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div className="flex items-center w-full gap-[48px]">
      {/* Label Section */}
      <label className="text-[#404040] font-bold flex justify-start items-center w-[264px]">
        {label}
        {required && <span className="text-[#EC1C24] ml-1">*</span>}
      </label>

      <div className="flex gap-[16px] items-center">
        <div className="w-[400px] cursor-pointer underline text-[#59B7B2]">{link}</div>

        {/* Copy Button */}
        {!copied && (
          <button onClick={handleCopy} className="text-[#404040] hover:text-[#EF7B22] transition">
            <ContentCopy />
          </button>
        )}
        {copied && (
          <span className="text-[#EF7B22] ">
            <Check />
          </span>
        )}
      </div>
    </div>
  );
}

export default GeneratedLinks;
