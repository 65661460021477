import React from "react";

function NoAssociations({ message }) {
  return (
    <div className="bg-[#F2F2F2] text-[#BFBFBF] h-[206px] w-full text-[18px] flex items-center justify-center rounded-[16px]">
      <p>{message ? message : "No associations"}</p>
    </div>
  );
}

export default NoAssociations;
