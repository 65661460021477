// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const navConfig = [
  {
    title: "products",
    path: "/dashboard/products",
    icon: icon("ic_products"),
    show: true
  },
  {
    title: "brands",
    path: "/dashboard/brands",
    icon: icon("ic_brands"),
    show: true
  },
  {
    title: "Orders",
    path: "/dashboard/orders",
    icon: icon("ic_orders"),
    show: true
  },
  {
    title: "CRM",
    path: "/dashboard/crm",
    icon: icon("ic_crm"),
    show: true
  },
  {
    title: "Promo Codes",
    path: "/dashboard/promos",
    icon: icon("ic_okoa"),
    show: true
  },
  {
    title: "Partners",
    path: "/dashboard/partners",
    icon: icon("ic_partners"),
    show: true
  }
];

export const chanelAdmnistrationNav = [
  {
    title: "Pamoja",
    path: "/dashboard/okoa/pamoja",
    icon: icon("ic_pamoja"),
    show: true
  },
  {
    title: "Jipange",
    path: "/dashboard/okoa/jipange",
    icon: icon("ic_jipange"),
    show: true
  }
];

export const configAdmnistrationNav = [
  {
    title: "Notification",
    path: "/dashboard/audit/notifications",
    icon: icon("ic_bell"),
    show: true
  },
  {
    title: "Audit",
    path: "/dashboard/audit/logs",
    icon: icon("ic_notebook"),
    show: true
  }
];
