import React from "react";
import { Close } from "@mui/icons-material";
function AssociationItem({ association, onRemove, isSelected = false, onClick }) {
  return (
    <div
      onClick={() => onClick(association)}
      className={`flex px-[16px] py-[16px] shadow-[0px_0px_4px_rgba(44,44,44,0.25)] ${isSelected ? "bg-[#232F3E]" : "bg-white"} border border-[#BFBFBF] justify-between items-center rounded-[8px] cursor-pointer`}
    >
      <div className="flex gap-2 items-center">
        <img
          src={association.imageUrl}
          alt="association"
          className="w-[40px] h-[40px] rounded-full object-fill"
        />
        <p className={`${isSelected ? "text-white" : "text-[#404040]"} text-[16px] font-semibold`}>
          {association?.name ?? association?.productName}
          {association?.unitName ? `- ${association.unitName}` : null}
        </p>
      </div>
      <Close
        className={`${isSelected ? "text-white" : "text-[#404040]"} text-[18px]`}
        onClick={() => onRemove(association)}
      />
    </div>
  );
}

export default AssociationItem;
