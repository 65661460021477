import React from "react";
import FormSection from "src/components/forms/formSection";
import TushopLabelInput from "src/components/forms/tushopLabelInput";
import TushopInput from "src/components/forms/tushopInput";
import GeneratedLinks from "src/components/forms/generatedLinks";
import TushopDropDown from "src/components/forms/tushopDropdown";
import TushopImageUploader from "src/components/forms/TushopImageUploader";
import TushopDragAndDropUploader from "src/components/forms/TushopDragAndDropUploader";
import { Controller } from "react-hook-form";
import { generatePartnerLink } from "../../utils/generators";

function PartnerDetailsFormSection({ formData, register, errors, control, setValue }) {
  const handleChange = () => {};
  const partnerDropDown = [
    { key: 1, label: "Single Brand" },
    { key: 2, label: "Multi Brand" }
  ];
  return (
    <FormSection title="Partner details">
      <div className="flex gap-[32px]">
        <span className="flex flex-col gap-[32px]">
          <TushopLabelInput label="Partner Cover Photo" alignment="items-start" required>
            <div className="flex flex-row">
              <Controller
                control={control}
                name="partnerData.coverPhoto"
                rules={{ required: "Partner cover photo is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <span className="flex flex-col gap-2">
                      <TushopImageUploader
                        fieldName="partnerData.coverPhoto"
                        label="PARTNER COVER PHOTO"
                        register={register}
                        error={error}
                        value={value}
                        className="w-[488px] h-[200px]"
                        onChange={(image) => onChange(image)}
                        firebasePath="/partners/cover-photos"
                      />
                      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                    </span>
                  );
                }}
              />
            </div>
          </TushopLabelInput>
          <TushopLabelInput label="Partner Name" required>
            <Controller
              control={control}
              name="partnerData.name"
              rules={{ required: "Partner name is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const handleChange = (e) => {
                  const value = e.target.value;
                  const sanitized = generatePartnerLink(value);
                  setValue("partnerData.partnerLink", sanitized);
                  onChange(value);
                };
                return (
                  <span className="flex flex-col gap-2">
                    <TushopInput
                      name="partnerData.name"
                      placeholder=""
                      value={value}
                      onChange={handleChange}
                    />
                    {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                  </span>
                );
              }}
            />
          </TushopLabelInput>
          <TushopLabelInput label="Partner Type" required>
            <Controller
              control={control}
              name="partnerData.partnerTypeId"
              rules={{ required: "Partner type is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const renderValue = partnerDropDown.find((item) => item.key === value);
                return (
                  <TushopDropDown
                    name="partnerData.partnerTypeId"
                    value={renderValue}
                    onChange={onChange}
                    options={partnerDropDown}
                    error={error}
                  />
                );
              }}
            />
          </TushopLabelInput>
          <Controller
            control={control}
            name="partnerData.partnerLink"
            rules={{ required: "Partner app link is required" }}
            render={({ field: { value } }) => {
              return (
                <span className="flex flex-col gap-2">
                  <GeneratedLinks label="Partner App Link" required link={value} />
                </span>
              );
            }}
          />

          <TushopLabelInput label="Partner Description" required alignment="items-start">
            <Controller
              control={control}
              name="partnerData.description"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <span className="flex flex-col gap-2">
                    <TushopInput
                      name="partnerData.description"
                      placeholder=""
                      value={value}
                      onChange={onChange}
                      type="textarea"
                    />
                    {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                  </span>
                );
              }}
            />
          </TushopLabelInput>
          <TushopLabelInput label="Customer Care Number" required>
            <TushopInput
              name="partnerData.customerCareNumber"
              placeholder=""
              register={register}
              errors={errors}
              value={formData.partnerData.customerCareNumber}
              onChange={handleChange}
              prefix="+254"
            />
          </TushopLabelInput>
          <TushopLabelInput label="Partner Images" alignment="items-start" required>
            <Controller
              control={control}
              name="partnerData.partnerImages"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <span className="flex flex-col gap-2">
                    <TushopImageUploader
                      fieldName="partnerData.partnerImages"
                      label="PARTNER IMAGES"
                      className="w-[488px] h-[200px]"
                      firebasePath="/partners/images"
                      multiple={true}
                      value={value}
                      onChange={(images) => onChange(images)}
                    />
                    {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                  </span>
                );
              }}
            />
          </TushopLabelInput>
        </span>
        <span className="py-2 w-[252px] justify-end flex flex-row">
          <Controller
            control={control}
            name="partnerData.logo"
            rules={{ required: "Partner logo is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <span className="flex flex-col gap-2">
                  <TushopImageUploader
                    fieldName="partnerData.logo"
                    label="PARTNER LOGO"
                    register={register}
                    error={error}
                    value={value}
                    onChange={(image) => onChange(image)}
                    firebasePath="/partners/logos"
                  />
                  {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                </span>
              );
            }}
          />
        </span>
      </div>
    </FormSection>
  );
}

export default PartnerDetailsFormSection;
