import React from "react";

const days = ["M", "T", "W", "T", "F", "S", "S"];

function TushopDaySelector({ selectedDays, onChange, disabled }) {
  const getDayIndex = (index) => (index !== 6 ? index + 1 : 0);

  const handleToggle = (index) => {
    if (disabled) return;
    let updatedDays;
    if (selectedDays.includes(index)) {
      updatedDays = selectedDays.filter((d) => d !== index);
    } else {
      updatedDays = [...selectedDays, index];
    }
    onChange(updatedDays);
  };

  return (
    <div className="flex flex-col items-start gap-1">
      <span className="text-gray-500 text-sm mb-[8px]">No delivery days</span>
      <div className="flex gap-2">
        {days.map((day, index) => {
          const dayIndex = getDayIndex(index);
          return (
            <button
              key={dayIndex}
              type="button"
              onClick={() => handleToggle(dayIndex)}
              className={`w-[30px] h-[30px] flex items-center justify-center rounded-full text-[14px] 
                ${selectedDays.includes(dayIndex) ? "bg-[#F2F2F2] border border-[#D9D9D9] text-[#404040] font-semibold" : "bg-[#F2F2F2] border border-[#F2F2F2] text-[#D9D9D9]"}`}
            >
              {day}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default TushopDaySelector;
