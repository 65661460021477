export const resetDeliverySection = () => {
  return {
    deliveredByPartner: false,
    deliveryRadius: 1,
    freeDeliveryThreshold: null,
    noDeliveryDays: [],
    deliveryMethodId: 1,
    deliveryFees: Array(5).fill({ distance: null, fee: null })
  };
};
