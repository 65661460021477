import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";
//
import { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array
};

export default function NavSection({ data = [], ...other }) {
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  if (isSeller) {
    data = data.filter((item) => item.path !== "/dashboard/promos");
  }
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => item.show && <NavItem key={item.title} item={item} />)}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------
NavItem.propTypes = {
  item: PropTypes.object
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const { dispatch } = useContext(GlobalContext);

  const clearStates = () => {
    dispatch({ type: "clear" });
  };

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      onClick={() => {
        clearStates();
      }}
      sx={{
        "&.active": {
          color: "white",
          bgcolor: "#EF7B22",
          fontWeight: "fontWeightBold"
        }
      }}
    >
      <StyledNavItemIcon>{icon ?? icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
