import React, { useState } from "react";
import NoAssociations from "./noAssociations";
import TushopSearchBox from "src/components/forms/tushopSearchBox";
import AssociationItem from "./AssociationItem";
import useAssociationStore from "../state/associationState";
import { Controller } from "react-hook-form";

function BrandAssociations({ control }) {
  const { setSelectedBrand, isSelectedBrand, addBrand, removeBrand, brands } =
    useAssociationStore();

  const addAssociation = (brand, currentStoreValue, onChange) => {
    const exists = currentStoreValue.some((b) => b === brand.branddId);
    if (!exists) {
      addBrand(brand);
      const updateBrandIds = currentStoreValue.concat(brand.brandId);
      onChange(updateBrandIds);
    }
  };

  const removeAssociation = (brand, currentStoreValue, onChange) => {
    const updatedBrands = currentStoreValue.filter((b) => b !== brand.brandId);
    removeBrand(brand.brandId);
    onChange(updatedBrands);
  };

  return (
    <Controller
      control={control}
      name="brandIds"
      rules={{
        validate: (value) => (value && value.length > 0 ? true : "Please select at least one brand")
      }}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <div className="flex flex-col w-[518px] gap-[16px]">
          <span className="flex flex-row justify-between gap-4 items-center">
            <h1 className="text-[#404040] text-[18px] font-semibold">
              Brands <span className="text-[#EC1C24]">*</span>
            </h1>
            {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
          </span>
          <TushopSearchBox
            apiEndpoint="/api/productService/admin/brands/fetch"
            onSelect={(brand) => addAssociation(brand, value, onChange)}
            dataKey="brands"
          />

          {value.length === 0 ? (
            <NoAssociations />
          ) : (
            <div className="flex flex-col gap-2 h-[206px] overflow-y-auto">
              {brands.map((association, index) => {
                const isSelected = isSelectedBrand(association);
                return (
                  <AssociationItem
                    key={index}
                    association={association}
                    isSelected={isSelected}
                    onRemove={() => removeAssociation(association, value, onChange)}
                    onClick={() => setSelectedBrand(association)}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    />
  );
}

export default BrandAssociations;
