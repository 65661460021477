import { create } from "zustand";

const usePopupStore = create((set) => ({
  isOpen: false,
  message: "",
  title: "",
  buttomName: null,
  onCancel: null,
  onConfirm: null,

  openPopup: ({ title, message, onConfirm, onCancel, buttonName }) =>
    set({
      isOpen: true,
      message,
      title,
      onConfirm,
      onCancel,
      buttonName: buttonName ?? null
    }),

  closePopup: () =>
    set({
      isOpen: false,
      message: "",
      title: "",
      onConfirm: null,
      onCancel: null,
      buttomName: null
    }),

  confirmPopup: () =>
    set((state) => {
      if (state.onConfirm) {
        state.onConfirm();
      }
      return {
        isOpen: false,
        message: "",
        title: "",
        onConfirm: null,
        onCancel: null,
        buttomName: null
      };
    }),

  cancelPopup: () =>
    set((state) => {
      if (state.onCancel) {
        state.onCancel(); // Optional: Call it if you want
      }
      return {
        isOpen: false,
        message: "",
        title: "",
        onConfirm: null,
        onCancel: null,
        buttomName: null
      };
    })
}));

export default usePopupStore;
