import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { useLoaderStore } from "../../state/useLoaderStore";

const GlobalLoader = () => {
  const loading = useLoaderStore((state) => state.loading);

  return (
    <Backdrop
      open={loading}
      sx={{
        color: "#ffff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalLoader;
