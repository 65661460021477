import { Close, Done, Error, Warning } from "@mui/icons-material";
import React from "react";

const AlertPopup = ({ alertDetail, clearAlert }) => {
  const alertColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  const iconColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  const borderColor = {
    warning: "#FFCC00",
    success: "#4CAF50",
    error: "#F44336"
  }[alertDetail?.type ?? "warning"];

  return (
    <div
      className={`fixed top-6 shadow rounded-lg z-[1000] sm:right-6 sm:w-[480px] right-4 w-[calc(100%-32px)]
  `}
    >
      <div
        className={`text-white flex justify-between items-start px-[8px] py-[8px] rounded-t-md`}
        style={{ backgroundColor: alertColor }}
      >
        <strong>{alertDetail?.title ?? ""}</strong>
        <Close
          color={iconColor}
          className="text-lg rounded cursor-pointer"
          onClick={() => clearAlert()}
        />
      </div>
      <div className="flex px-[8px] py-[16px] rounded-b-md bg-white gap-[8px] justify-start">
        <div
          className={`rounded-full border flex items-center justify-center w-[32px] h-[32px] p-3 text-[#2c2c2c]`}
          style={{ borderColor: borderColor, color: iconColor }}
        >
          {alertDetail?.type === "warning" ? (
            <Warning className="text-xl" />
          ) : alertDetail?.type === "success" ? (
            <Done className="text-xl" />
          ) : alertDetail?.type === "error" ? (
            <Error className="text-xl" />
          ) : (
            <Close fontSize="28px" />
          )}
        </div>
        <div className="flex flex-col justify-between text-[16px] leading-[24px] gap-[8px]">
          <div className="font-bold text-sm leading-tight text-[#2c2c2c]">
            {alertDetail?.heading ?? ""}
          </div>
          <div className="font-normal text-xs leading-tight text-[#595959]">
            {alertDetail?.body ?? "Something went wrong"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
