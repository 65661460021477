import { create } from "zustand";

const usePageHeaderStore = create((set) => ({
  title: "",
  showSearch: false,
  showFilter: false,
  activeFilterType: null,
  onSearch: () => {},
  onFilter: () => {},

  setTitle: (title) => set(() => ({ title })),

  showSearchField: () => set(() => ({ showSearch: true })),
  hideSearchField: () => set(() => ({ showSearch: false })),

  showFilterButton: () => set(() => ({ showFilter: true })),
  hideFilterButton: () => set(() => ({ showFilter: false })),

  setOnSearch: (fn) => set(() => ({ onSearch: fn })),
  setOnFilter: (fn) => set(() => ({ onFilter: fn })),
  setFilterType: (type) => set(() => ({ activeFilterType: type })),
  clearFilterType: () => set(() => ({ activeFilterType: null }))
}));

export default usePageHeaderStore;
