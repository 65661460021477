import { lazy, useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

//
import { verifyTokenExpiry } from "./utils/verifyToken";
import { isUserSeller } from "./constants/user_constants";

import { DeliveryRequestProvider } from "./pages/deliver_request/context/deliveryRequestContext";

import Page404 from "./pages/404/Page404";
import LoginPage from "./pages/login/LoginPage";
import EditPartner from "./pages/partners/edit/editPartner";

const PamojaPage = lazy(() => import("./pages/pamoja/PamojaPage"));
const PromoDetails = lazy(() => import("./pages/promo/PromoDetails"));
const PromoPage = lazy(() => import("./pages/promo/PromoPage"));
const AuditPage = lazy(() => import("./pages/audit/AuditPage"));
const BrandDetails = lazy(() => import("./pages/brand/BrandDetails"));
const BrandPage = lazy(() => import("./pages/brand/BrandPage"));
const CategoryPage = lazy(() => import("./pages/category/CategoryPage"));
const CRMDetails = lazy(() => import("./pages/crm/CRMDetails"));
const DeliverRequestPage = lazy(() => import("./pages/deliver_request/delivery_request"));
const CRMPage = lazy(() => import("./pages/crm/CRMPage"));
const JipangeConfigurationPage = lazy(() => import("./pages/jipange/JipangeConfigurationPage"));
const ResetPasswordPage = lazy(() => import("./pages/reset_password/ResetPasswordPage"));
const PromoNotificationPage = lazy(() => import("./pages/notification/PromoNotificationPage"));

//Partners
const PartnersPage = lazy(() => import("./pages/partners/index"));
const PartnersList = lazy(() => import("./pages/partners/list/partnersList"));
const AddPartner = lazy(() => import("./pages/partners/add/addPartner"));

//Products
const ProductPage = lazy(() => import("./pages/product/index"));
const ProductsList = lazy(() => import("./pages/product/list/productsList"));
const AddProduct = lazy(() => import("./pages/product/add/addProduct"));
const EditProduct = lazy(() => import("./pages/product/edit/editProduct"));
const AddVariant = lazy(() => import("./pages/product/variant/addVariant"));

//Orders
const OrdersPage = lazy(() => import("./pages/orders/index"));
const OrdersList = lazy(() => import("./pages/orders/list/orderList"));
const OrderDetailsPage = lazy(() => import("./pages/orders/details/orderDetails"));

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // Check token status
  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else {
      verifyTokenExpiry(token, navigate);
    }
  }, [token, navigate]);

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        {
          path: "/dashboard/products",
          element: <ProductPage />,
          children: [
            { path: "", element: <ProductsList /> },
            { path: "add", element: <AddProduct /> },
            { path: "variant/add", element: <AddVariant /> },
            { path: "edit/:productVariantId", element: <EditProduct /> }
          ]
        },

        { path: "brands", element: <BrandPage /> },
        { path: "brands/details/:brandId", element: <BrandDetails /> },
        {
          path: "/dashboard/orders",
          element: <OrdersPage />,
          children: [
            { path: "", element: <OrdersList /> },
            { path: "details/:orderId", element: <OrderDetailsPage /> }
          ]
        },
        { path: "", element: <OrdersPage /> },
        {
          path: "orders/delivery-request",
          element: (
            <DeliveryRequestProvider>
              <DeliverRequestPage />
            </DeliveryRequestProvider>
          )
        },
        { path: "crm", element: <CRMPage /> },
        { path: "crm/details/:customerId", element: <CRMDetails /> },
        { path: "categories", element: <CategoryPage /> },
        { path: "promos", element: <PromoPage /> },
        { path: "promos/details/:promoId", element: <PromoDetails /> },
        {
          path: "/dashboard/partners",
          element: <PartnersPage />,
          children: [
            { path: "", element: <PartnersList /> },
            { path: "edit/:partnerId", element: <EditPartner /> },
            { path: "add", element: <AddPartner /> }
          ]
        }
      ]
    },
    {
      path: "/dashboard/okoa",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "pamoja", element: <PamojaPage /> },
        { path: "jipange", element: <JipangeConfigurationPage /> }
      ]
    },
    {
      path: "/dashboard/audit",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/settings" />, index: true },
        { path: "logs", element: <AuditPage /> },
        { path: "notifications", element: <PromoNotificationPage /> }
      ]
    },

    {
      path: "login",
      element: <LoginPage />
    },
    {
      path: "reset-password",
      element: <ResetPasswordPage />
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />
    }
  ]);

  return routes;
}
