import React, { useEffect } from "react";
import FormSection from "src/components/forms/formSection";
import TushopLabelInput from "src/components/forms/tushopLabelInput";
import TushopInput from "src/components/forms/tushopInput";
import TushopGooglePlaceSearch from "src/components/forms/tushopGooglePlacesSearch";
import TushopGoogleMap from "src/components/ui/TushopGoogleMap";
import TushopDaySelector from "src/components/ui/TushopDaySelector";
import TushopDropDown from "src/components/forms/tushopDropdown";
import TushopSwitchButton from "src/components/forms/tushopRadioButton";
import AddPartnerDeliveryFees from "../../components/deliveryFees";
import { Controller } from "react-hook-form";

function DeliveryFormSection({ formData, register, errors, control, setValue, disabled }) {
  const deliveryMethodOptions = [
    { key: 4, label: "90 min" },
    { key: 5, label: "Next Day" },
    { key: 6, label: "In 2 days" }
  ];

  // const handleToggleDeliveredByPartner = (name, value) => {
  //   handleToggle(name, value);
  //   if (value) {
  //     handleLocationChange(tushopLocation);
  //   }
  // };

  return (
    <FormSection title="Delivery">
      <TushopLabelInput label="Delivered by Partner" required>
        <Controller
          control={control}
          name="deliveryData.deliveredByPartner"
          render={({ field: { onChange, value } }) => {
            return (
              <TushopSwitchButton
                name="deliveryData.deliveredByPartner"
                checked={value}
                onChange={() => onChange(!value)}
              />
            );
          }}
        />
      </TushopLabelInput>
      <div className="flex w-full gap-8">
        <div>
          <TushopLabelInput label="Set Store Location" required>
            <Controller
              control={control}
              name="deliveryData.location"
              rules={{ required: "Location is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                console.log({ value });
                return (
                  <TushopGooglePlaceSearch
                    value={value}
                    onChange={(location) => onChange(location)}
                    disabled={disabled}
                  />
                );
              }}
            />
          </TushopLabelInput>
          <TushopLabelInput label="">
            <Controller
              control={control}
              name="deliveryData.location"
              rules={{ required: "Location is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <TushopGoogleMap
                    location={value}
                    radius={formData.deliveryData.deliveryRadius}
                    disabled={disabled}
                  />
                );
              }}
            />
          </TushopLabelInput>
        </div>

        <Controller
          control={control}
          name="deliveryData.location"
          rules={{ required: "Location is required" }}
          render={({ field: { value }, fieldState: { error } }) => {
            const hasValue =
              value &&
              (value.name ||
                value.street ||
                value.administrativeArea ||
                value.city ||
                value.country);

            return (
              hasValue && (
                <div className="flex flex-col items-start text-[#404040] p-4 rounded-md">
                  <h1 className="text-[16px] font-semibold">Address</h1>
                  <span className="h-[8px]" />
                  {value.name && <h2 className="font-semibold">{value.name}</h2>}
                  {(value.street || value.administrativeArea) && (
                    <h2>
                      {value.street || ""}
                      {value.street && value.administrativeArea ? ", " : ""}
                      {value.administrativeArea || ""}
                    </h2>
                  )}
                  {(value.city || value.country) && (
                    <h2>
                      {value.city || ""}
                      {value.city && value.country ? ", " : ""}
                      {value.country || ""}
                    </h2>
                  )}
                </div>
              )
            );
          }}
        />
      </div>
      <TushopLabelInput label="Radius" required>
        <Controller
          control={control}
          name="deliveryData.deliveryRadius"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <span className="flex flex-col gap-2">
              <div className="flex items-center gap-[8px] text-[14px] select-none">
                <span>Set serviceable radius of the store as</span>
                <span className="w-[44px]">
                  <TushopInput
                    name="deliveryData.deliveryRadius"
                    disabled={disabled}
                    placeholder=""
                    value={value}
                    onChange={onChange}
                    className="py-0"
                  />
                </span>
                <span>km</span>
              </div>
              {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
            </span>
          )}
        />
      </TushopLabelInput>
      <TushopLabelInput label="Delivery Times" required>
        <Controller
          control={control}
          name="deliveryData.deliveryMethodId"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <span className="flex flex-col gap-2">
              <div className="flex w-full  gap-[56px] items-start">
                <div className="flex flex-col">
                  <span className="text-gray-500 text-sm">Delivery Time</span>
                  <span className="w-32">
                    <span className="flex flex-col gap-2">
                      <TushopDropDown
                        disabled={disabled}
                        value={deliveryMethodOptions.find((item) => item.key === value) || null}
                        onChange={(value) => onChange(value)}
                        options={deliveryMethodOptions}
                      />
                    </span>
                  </span>
                </div>
                <div>
                  <Controller
                    control={control}
                    name="deliveryData.noDeliveryDays"
                    render={({ field: { onChange, value } }) => (
                      <TushopDaySelector
                        selectedDays={value}
                        onChange={(value) => onChange(value)}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>
              </div>
              {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
            </span>
          )}
        />
      </TushopLabelInput>
      <TushopLabelInput label="Delivery Fees" alignment="items-start" className="" required>
        <Controller
          control={control}
          name="deliveryData.deliveryFees"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const handleChange = (index, field, fieldValue) => {
              const updatedFees = value.map((fee, i) =>
                i === index ? { ...fee, [field]: fieldValue } : fee
              );
              onChange(updatedFees); // Update form data
            };
            return (
              <span className="flex flex-col gap-2 ">
                <div className="flex flex-col gap-4 ">
                  <AddPartnerDeliveryFees
                    disabled={disabled}
                    formData={formData}
                    deliveryFees={value}
                    onUpdate={handleChange}
                  />
                  <div className="flex items-center gap-[8px] text-[14px]">
                    <span>Orders worth</span>
                    <span className="w-[65px]">
                      <Controller
                        control={control}
                        name="deliveryData.freeDeliveryThreshold"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TushopInput
                              type="number"
                              prefix="Ksh"
                              placeholder=""
                              name="deliveryData.freeDeliveryThreshold"
                              value={formData.deliveryData.freeDeliveryThreshold ?? ""}
                              onChange={onChange}
                              disabled={disabled}
                            />
                          );
                        }}
                      />
                    </span>
                    <span>and above get FREE Delivery</span>
                  </div>
                </div>
                {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
              </span>
            );
          }}
        />
      </TushopLabelInput>
    </FormSection>
  );
}

export default DeliveryFormSection;
