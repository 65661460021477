import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import { Search, LocationOn } from "@mui/icons-material";
import axiosInstance from "src/utils/axiosInstance";

function TushopGooglePlaceSearch({
  value,
  onChange,
  placeholder = "Search for a location...",
  disabled
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);

  const debouncedFetch = useRef(null); // Store debounced function reference

  const fetchAutocompleteResults = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/api/userService/places/autocomplete", {
        params: { input: query }
      });

      setSuggestions(response); // Expecting an array of predictions
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch place details by placeId
  const fetchPlaceDetails = async (placeId) => {
    if (!placeId) return;

    setIsFetchingDetails(true);
    try {
      const response = await axiosInstance.get("/api/userService/places/search", {
        params: { placeId: placeId }
      });

      onChange(response); // Expecting full place details in response
    } catch (error) {
      console.error("Error fetching place details:", error);
    } finally {
      setIsFetchingDetails(false);
    }
  };

  // Initialize debounce function once
  useEffect(() => {
    debouncedFetch.current = debounce(fetchAutocompleteResults, 500);
    return () => debouncedFetch.current.cancel(); // Cleanup debounce on unmount
  }, []);

  // Call debounced function when input changes
  useEffect(() => {
    if (debouncedFetch.current) {
      debouncedFetch.current(value.description);
    }
  }, [value.description]);

  // Handle input change
  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    onChange({ description: searchTerm, location: {} });
  };

  // Handle selection
  const handleSuggestionClick = (suggestion) => {
    setSuggestions([]);
    fetchPlaceDetails(suggestion.placeId);
  };

  return (
    <div className="relative w-full select-none">
      {/* Search Input */}
      <div
        className={`flex items-center border border-gray-300 rounded-md px-3 py-2  ${disabled ? "opacity-50 bg-[#D9D9D9]" : ""}`}
      >
        <input
          disabled={disabled}
          type="text"
          value={value.description}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          placeholder={placeholder}
          className={`w-full outline-none bg-transparent  `}
        />
        {isLoading || isFetchingDetails ? (
          <svg className="animate-spin h-5 w-5 text-[#EF7B22]" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="#EF7B22"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="#EF7B22"
              d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 000 16v-4l3 3-3 3v-4a8 8 0 01-8-8z"
            ></path>
          </svg>
        ) : (
          <span className="material-symbols-outlined text-gray-500">
            <Search />
          </span>
        )}
      </div>

      {/* Scrollable Dropdown List */}
      {isFocused && suggestions.length > 0 && (
        <ul
          className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 overflow-y-auto"
          style={{ maxHeight: "250px" }} // Set max height so scrolling works when list is too long
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onMouseDown={() => handleSuggestionClick(suggestion)}
              className="px-4 py-2 cursor-pointer flex space-x-2 text-neutral-700 hover:text-white hover:bg-[#EF7B22]"
            >
              <span className="material-symbols-outlined">
                <LocationOn />
              </span>
              <span className="flex flex-col text-sm font-normal">
                <span>{suggestion.name}</span>
                <span>{suggestion.address}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TushopGooglePlaceSearch;
