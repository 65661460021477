import React from "react";
import NoAssociations from "./noAssociations";
import TushopSearchBox from "src/components/forms/tushopSearchBox";
import useAssociationStore from "../state/associationState";
import { Controller } from "react-hook-form";
import AssociationItem from "./AssociationItem";

function ProductAssociations({ control }) {
  const {
    selectedBrand,
    addProduct,
    removeProduct,
    products,
    hasBrandSelected,
    hasBrands,
    getSelectedBrandProducts
  } = useAssociationStore();

  const addAssociation = (product, currentStoreValue, onChange) => {
    const exists = currentStoreValue.some((p) => p === product.productVariantId);
    if (!exists) {
      console.log("Add Product", product);
      addProduct(product);
      const updateProductVariantIds = currentStoreValue.concat(product.productVariantId);
      onChange(updateProductVariantIds);
    }
  };

  const removeAssociation = (product, currentStoreValue, onChange) => {
    const updatedProducts = currentStoreValue.filter((p) => p !== product.productVariantId);
    removeProduct(product.productVariantId);
    onChange(updatedProducts);
  };

  const getAssociations = () => {
    const associations = products.filter((p) => p.brandId === selectedBrand.brandId);
    return associations;
  };

  return (
    <Controller
      control={control}
      name="productVariantIds"
      rules={{
        validate: (value) =>
          value && value.length > 0 ? true : "Please select at least one product"
      }}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => {
        return (
          <div className="flex flex-col w-[518px] gap-[16px] ">
            <span className="flex flex-row justify-between gap-4 items-center">
              <h1 className="text-[#404040] text-[18px] font-semibold">
                Products <span className="text-[#EC1C24]">*</span>
              </h1>
              {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
            </span>
            <TushopSearchBox
              method="POST"
              apiEndpoint={
                selectedBrand != null ? `/api/productService/admin/brand/products/search` : ""
              }
              onSelect={(product) => addAssociation(product, value, onChange)}
              dataKey="products"
              postParams={selectedBrand != null ? { brandIds: [selectedBrand.brandId] } : null}
              disabled={!hasBrandSelected()}
            />
            {!hasBrands ? (
              <NoAssociations />
            ) : selectedBrand == null ? (
              <NoAssociations message="Please select a brand" />
            ) : getAssociations().length === 0 ? (
              <NoAssociations />
            ) : (
              <div className="flex flex-col gap-2 h-[206px] overflow-y-auto">
                {getSelectedBrandProducts().map((association, index) => {
                  console.log({ association });
                  return (
                    <AssociationItem
                      key={index}
                      association={association}
                      isSelected={false}
                      onRemove={() => removeAssociation(association, value, onChange)}
                      onClick={() => {}}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

export default ProductAssociations;
