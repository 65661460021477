export function generatePartnerLink(name) {
  if (!name || typeof name !== "string") return "";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let environment = "tushop.io";
  if (baseUrl.includes("dev-api")) {
    environment = `dev-web.tushop.io`;
  } else if (baseUrl.includes("qa-api")) {
    environment = "qa-web.tushop.io";
  }
  // Remove URLs and special characters except spaces
  const sanitized = name
    .replace(/https?:\/\/|www\./gi, "") // Remove http(s):// or www.
    .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove special characters except spaces and hyphens
    .trim()
    .toLowerCase()
    .split(/\s+/) // Handles multiple spaces
    .join("-"); // Joins words with '-'

  return sanitized ? `https://${environment}/sellers/${sanitized}` : "";
}
