import React from "react";
import UserDetailsFormSection from "./sections/userDetailsFormSection";
import PartnerDetailsFormSection from "./sections/partnerDetailsFormSection";
import AssociationsFormSection from "./sections/associationsFormSection";
import DeliveryFormSection from "./sections/deliveryFormSection";
import AddPartnerHeader from "../components/addPartnerHeader";
import { FormProvider } from "react-hook-form";

function PartnerForm({
  formData,
  setValue,
  handleSubmit,
  onSubmit,
  deliveryByPartner,
  methods,
  errors,
  register,
  control,
  disabled
}) {
  return (
    <FormProvider {...methods} context={{ deliveredByPartner: deliveryByPartner }}>
      <form className="flex flex-col select-none" onSubmit={handleSubmit(onSubmit)}>
        <AddPartnerHeader setValue={setValue} handleSubmit={handleSubmit} onSubmit={onSubmit} />
        <div className="pl-[40px] py-[32px] flex flex-col">
          <UserDetailsFormSection register={register} errors={errors} formData={formData} />
          <div className="w-full h-[1px] bg-[#D9D9D9] mb-[36px]" />
          <PartnerDetailsFormSection
            errors={errors}
            register={register}
            formData={formData}
            setValue={setValue}
            control={control}
          />
          <div className="w-full h-[1px] bg-[#D9D9D9] mb-[36px]" />
          <AssociationsFormSection
            register={register}
            errors={errors}
            control={control}
            formData={formData}
            setValues={setValue}
          />
          <div className="w-full h-[1px] bg-[#D9D9D9] mb-[36px]" />
          <DeliveryFormSection
            errors={errors}
            register={register}
            formData={formData}
            control={control}
            disabled={disabled}
            setValue={setValue}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default PartnerForm;
