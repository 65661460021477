import React from "react";
import PropTypes from "prop-types";

function TushopButton({
  className = "",
  variant = "primary",
  buttonBackgroundClass = null,
  text,
  icon,
  onClick,
  type = "button",
  disabled = false
}) {
  // Define styles based on the variant
  const variantClass =
    variant === "primary" ? "text-white" : "text-[#404040] border border-[#404040]";

  buttonBackgroundClass = buttonBackgroundClass
    ? buttonBackgroundClass
    : variant == "primary"
      ? "bg-[#EF7B22]"
      : "bg-white";
  return (
    <button
      className={`${variantClass}   ${buttonBackgroundClass} px-[16px] py-[8px] rounded-[8px] font-semibold flex flex-row justify-center ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <div className=" flex justify-center items-center ">
        {text}
        {icon && <div className="flex ml-[6px]">{icon}</div>}
      </div>
    </button>
  );
}

TushopButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  type: PropTypes.string
};

export default TushopButton;
