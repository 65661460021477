import React, { useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker, Circle } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

const mapContainerStyle = {
  width: "100%",
  height: "442px",
  borderRadius: "0px"
};

const defaultCenter = {
  lat: -1.286389, // Default to Nairobi
  lng: 36.817223
};

/**
 * @param {Object} location - The location object containing latitude and longitude.
 * @param {number} radius - The radius of the search area in kilometers.
 * @returns A Google Map component with a marker and radius circle.
 */
function TushopGoogleMap({ location, radius, disabled = false }) {
  const mapRef = useRef(null);
  const markerRef = useRef(null); // Store marker reference

  // Adjust map bounds when `location` or `radius` changes
  useEffect(() => {
    if (mapRef.current && location.latitude) {
      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds to include the radius
      bounds.extend({ lat: location.latitude + radius / 111, lng: location.longitude });
      bounds.extend({ lat: location.latitude - radius / 111, lng: location.longitude });
      bounds.extend({ lat: location.latitude, lng: location.longitude + radius / 111 });
      bounds.extend({ lat: location.latitude, lng: location.longitude - radius / 111 });

      mapRef.current.fitBounds(bounds);
    }
  }, [location, radius]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        streetView={true}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          disableDefaultUI: disabled
        }}
        mapContainerStyle={mapContainerStyle}
        center={
          location.latitude ? { lat: location.latitude, lng: location.longitude } : defaultCenter
        }
        zoom={14} // Default zoom, dynamically adjusted later
        onLoad={(map) => (mapRef.current = map)}
      >
        {/* Marker for the selected location */}
        {location.latitude && (
          <Marker
            key={`${location.latitude}-${location.longitude}`} // Forces re-render when location changes
            position={{ lat: location.latitude, lng: location.longitude }}
            onLoad={(marker) => (markerRef.current = marker)}
          />
        )}

        {/* Dynamic Radius Circle */}
        {location.latitude && radius && (
          <Circle
            center={{ lat: location.latitude, lng: location.longitude }}
            radius={radius * 1000} // Convert KM to meters
            options={{
              fillColor: "#EF7B22",
              fillOpacity: 0.2,
              strokeColor: "#EF7B22",
              strokeOpacity: 0.5,
              strokeWeight: 2
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default TushopGoogleMap;
