import React from "react";

/**
 *
 * @param {label} label - The label text for the input field.
 * @param {required} required - Whether the input field is required.
 * @param {children} children - The content of the input field.
 * @param {alignment} alignment - The alignment of the label and input field. Default is "items-center".
 * @returns  A labeled input field with the specified label, required status, and content.
 */
function TushopLabelInput({
  label,
  required = false,
  children,
  alignment = "items-center",
  className = "w-[500px]",
  gapSpacing = "gap-[48px]",
  labelClassName = "w-[254px]",
  wrapperStyling = "",
  icon
}) {
  return (
    <div className={`flex ${alignment} w-full py-2 ${gapSpacing} ${wrapperStyling}`}>
      {/* Label Section */}
      <label
        className={`text-[#404040] font-semibold flex justify-start items-center ${labelClassName}`}
      >
        {label} {icon ? icon : null}
        {required && <span className="text-[#EC1C24] ml-1">*</span>}
      </label>

      <div className={`${className}`}>{children}</div>
    </div>
  );
}

export default TushopLabelInput;
