import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "src/utils/axiosInstance";
import debounce from "lodash.debounce";
import { Search } from "@mui/icons-material";

function TushopSearchBox({
  placeholder = "Search",
  method = "GET",
  postParams = {},
  apiEndpoint,
  onSelect,
  dataKey,
  disabled = false,
  error = null,
  showValue = false,
  displayKey = null
}) {
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  // Debounce API calls to prevent excessive requests
  const fetchResults = debounce(async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    try {
      let response;
      if (method === "GET") {
        response = await axiosInstance.get(apiEndpoint, {
          params: { searchText: query, pageSize: 20, pageNumber: 0 }
        });
      } else if (method === "POST") {
        response = await axiosInstance.post(apiEndpoint, {
          searchText: query,
          ...postParams,
          pageSize: 20,
          pageNumber: 0
        });
      }

      console.log("Response:: ", response[dataKey]);

      setSuggestions(dataKey ? response[dataKey] || [] : response || []); //
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (searchTerm) {
      fetchResults(searchTerm);
    }
  }, [searchTerm]);

  // Handle input change
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setInputValue(e.target.value);
  };

  // Handle result selection
  const handleSelect = (result) => {
    onSelect(result); // Pass selected result back to parent
    setInputValue(
      showValue && displayKey ? result[displayKey] : showValue && !displayKey ? result.name : ""
    ); // Update input field
    setSearchTerm("");
    setSuggestions([]); // Close dropdown
  };

  return (
    <div className="relative w-full">
      {/* Search Input */}
      <div
        className={`flex items-center border ${error ? "border-red-500" : "border-gray-300"} rounded-md px-3 py-2 bg-transparent`}
      >
        <input
          disabled={disabled}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          placeholder={placeholder}
          className="w-full outline-none bg-transparent text-gray-700"
        />
        {isLoading ? (
          <svg className="animate-spin h-5 w-5 text-[#EF7B22]" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="#EF7B22"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="#EF7B22"
              d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 000 16v-4l3 3-3 3v-4a8 8 0 01-8-8z"
            ></path>
          </svg>
        ) : (
          <Search className="text-gray-500" />
        )}
      </div>

      {/* Dropdown List */}
      {isFocused && suggestions.length > 0 && (
        <ul
          ref={dropdownRef}
          className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 max-h-[160px] overflow-y-auto"
        >
          {suggestions.slice(0, 6).map((result, index) => (
            <li
              key={index}
              onMouseDown={() => handleSelect(result)}
              className="px-4 py-2 cursor-pointer flex space-x-2 text-neutral-700 hover:text-white hover:bg-[#EF7B22]"
            >
              <span>{displayKey ? result[displayKey] : result.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TushopSearchBox;
