import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import TushopButton from "src/components/ui/TushopButton";

function AddPartnerHeader({ onSubmit, setValue, handleSubmit }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard/partners/");
    }
  };

  const handleSave = () => {
    setValue("publish", false);
    setTimeout(() => handleSubmit(onSubmit)(), 0);
  };

  const handlePublish = () => {
    setValue("publish", true);
    setTimeout(() => handleSubmit(onSubmit)(), 0);
  };

  return (
    <div className="flex justify-between items-center mb-[16px] ">
      <button className="flex gap-[16px] font-bold text-[18px] items-center" onClick={handleGoBack}>
        <ArrowBack /> <span>Account Details</span>
      </button>
      <div className="flex gap-[16px]">
        <TushopButton
          text="Cancel"
          variant="secondary"
          className="w-[120px] h-[40px]"
          onClick={handleGoBack}
        />
        <TushopButton
          text="Save"
          variant="secondary"
          className="w-[120px] h-[40px]"
          onClick={handleSave}
          type="button"
        />
        <TushopButton
          text="Publish"
          className="w-[120px] h-[40px]"
          onClick={handlePublish}
          type="button"
        />
      </div>
    </div>
  );
}

export default AddPartnerHeader;
