import React from "react";
import FormSection from "src/components/forms/formSection";
import BrandAssociations from "../../components/BrandAssociations";
import ProductAssociations from "../../components/ProductAssociations";

function AssociationsFormSection({ control, setValues, getValues }) {
  return (
    <FormSection title="Associations">
      <div className="flex gap-[48px]">
        <BrandAssociations control={control} setValues={setValues} />
        <ProductAssociations control={control} setValues={setValues} />
      </div>
    </FormSection>
  );
}

export default AssociationsFormSection;
