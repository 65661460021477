import { update } from "lodash";
import axiosInstance from "src/utils/axiosInstance";

const service = (axios) => {
  const basePath = "/api/userService";

  return {
    getPartners: async ({ pageSize, pageNumber, searchText }) => {
      try {
        const queryParams = new URLSearchParams();
        queryParams.append("pageSize", pageSize);
        queryParams.append("pageNumber", pageNumber);
        if (searchText && searchText !== "") {
          queryParams.append("searchText", searchText);
        }
        const response = await axios.get(`${basePath}/admin/partner?${queryParams.toString()}`);
        console.log({ response });
        return response;
      } catch (e) {
        console.log({ e });
        throw new Error(e?.response?.data?.displayMessage ?? "Unable to get partners.");
      }
    },
    addPartner: async (formData) => {
      console.log({ formData });
      try {
        const response = await axios.post(`${basePath}/admin/partner`, formData);
        return response;
      } catch (error) {
        throw new Error(error?.response?.data?.displayMessage ?? "Unable to complete.");
      }
    },
    updatePartner: async (formData) => {
      try {
        console.log({ formData });
        const response = await axios.post(`${basePath}/admin/partner`, formData);
        return response;
      } catch (error) {
        console.log({ error });
        throw new Error(error?.response?.data?.displayMessage ?? "Unable to complete.");
      }
    },

    getPartner: async (partnerId) => {
      try {
        const response = await axios.get(`${basePath}/admin/partner/${partnerId}/details/fetch`);
        console.log({ response });
        return response;
      } catch (e) {
        throw new Error(e?.response?.data?.displayMessage ?? "Unable to get partner.");
      }
    },
    exportPartner: async () => {
      try {
        const response = await axios.get(`${basePath}/admin/partner/export`, {
          responseType: "blob"
        });

        // Create a Blob from the CSV data
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "partners.csv";
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (e) {
        throw new Error(e?.response?.data?.displayMessage ?? "Unable to export partner.");
      }
    }
  };
};

export const partnerService = service(axiosInstance);
