import { create } from "zustand";

export const useLoaderStore = create((set) => ({
  loading: false,

  showLoader: () => set({ loading: true }),
  hideLoader: () => set({ loading: false })
}));

export const showLoader = () => useLoaderStore.getState().showLoader();
export const hideLoader = () => useLoaderStore.getState().hideLoader();
