import React from "react";
import FormSection from "src/components/forms/formSection";
import TushopLabelInput from "src/components/forms/tushopLabelInput";
import TushopInput from "src/components/forms/tushopInput";

function UserDetailsFormSection({ register, errors, formData }) {
  console.log({ errors });
  const handleChange = (e) => {};
  return (
    <FormSection title="User details">
      <TushopLabelInput label="First Name" required>
        <TushopInput
          register={register}
          errors={errors}
          name="userData.firstName"
          placeholder=""
          value={formData.userData.firstName}
          onChange={handleChange}
        />
      </TushopLabelInput>
      <TushopLabelInput label="Last Name" required>
        <TushopInput
          name="userData.lastName"
          placeholder=""
          value={formData.userData.lastName}
          register={register}
          errors={errors}
        />
      </TushopLabelInput>
      <TushopLabelInput label="Email ID" required>
        <TushopInput
          name="userData.email"
          placeholder=""
          register={register}
          errors={errors}
          value={formData.userData.email}
          onChange={handleChange}
        />
      </TushopLabelInput>
      <TushopLabelInput label="Phone" required>
        <TushopInput
          name="userData.phoneNumber"
          placeholder=""
          register={register}
          errors={errors}
          value={formData.userData.phoneNumber}
          onChange={handleChange}
        />
      </TushopLabelInput>
    </FormSection>
  );
}

export default UserDetailsFormSection;
