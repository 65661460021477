import React from "react";

/**
 * @param {title} title - The title of the section.
 * @param {children} children - The content of the section.
 * @returns A section with the specified title and content.
 */
function FormSection({ title, children }) {
  return (
    <div className="flex flex-col pb-[32px] w-full">
      <div className="text-[#8C8C8C] font-[400px] text-[22px] ">{title}</div>
      <div className="h-[40px]"></div>
      <div className="gap-[32px] flex flex-col">{children}</div>
    </div>
  );
}

export default FormSection;
