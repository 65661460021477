const { create } = require("zustand");

const useAlertStore = create((set) => ({
  showMessage: false,
  alertDetail: {
    title: null,
    heading: null,
    body: null,
    type: "warning"
  },

  showAlert: async (item) => {
    try {
      set((state) => ({
        ...state,
        alertDetail: item,
        showMessage: true
      }));
    } catch (error) {
      console.error("Failed to update store data", error?.message);
    }
  },

  clearAlert: async () => {
    try {
      set((state) => ({
        ...state,
        showMessage: false,
        alertDetail: {
          title: null,
          heading: null,
          body: null,
          type: "warning"
        }
      }));
    } catch (error) {
      console.error("Failed to reset store data", error?.message);
    }
  }
}));

export default useAlertStore;
