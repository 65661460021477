import { create } from "zustand";

const useAssociationStore = create((set, get) => ({
  selectedBrand: null,
  brands: [],
  products: [],

  setBrands: (brands) => {
    set(() => ({ brands }));
  },

  setProducts: (products) => set(() => ({ products })),

  removeSelectedBrand: () => {
    set({ selectedBrand: null });
  },

  setSelectedBrand: (brand) =>
    set((state) => ({
      selectedBrand: state.selectedBrand?.brandId === brand.brandId ? null : brand
    })),

  isSelectedBrand: (brand) => get().selectedBrand?.brandId === brand.brandId,

  hasBrandSelected: () => get().selectedBrand !== null,

  addBrand: (brand) =>
    set((state) => ({
      brands: [...state.brands, brand]
    })),

  resetAssociationStore: () =>
    set({
      selectedBrand: null,
      brands: [],
      products: []
    }),

  removeBrand: (brandId) =>
    set((state) => {
      const updatedBrands = state.brands.filter((brand) => brand.brandId !== brandId);
      const isSelectedRemoved = state.selectedBrand?.brandId === brandId;
      return {
        brands: updatedBrands,
        selectedBrand: isSelectedRemoved ? null : state.selectedBrand
      };
    }),

  addProduct: (product) =>
    set((state) => ({
      products: [...state.products, product]
    })),

  removeProduct: (productId) =>
    set((state) => {
      const productToRemove = state.products.find(
        (product) => product.productVariantId === productId
      );
      const updatedProducts = state.products.filter((product) => product !== productToRemove);

      return {
        products: updatedProducts
      };
    }),

  getSelectedBrandProducts: () => {
    const { selectedBrand, products } = get();
    if (!selectedBrand) return [];
    return products.filter((product) => product.brandId === selectedBrand.brandId);
  },

  hasBrands: () => get().brands.length > 0
}));

export default useAssociationStore;
