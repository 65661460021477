import React, { useState, useRef, useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";

/**
 * @param {options} options - An array of options for the dropdown.
 * @param {value} value - The selected value of the dropdown.
 * @param {onChange} onChange - The function to be called when the dropdown value is changed.
 * @param {register} register - React Hook Form register function.
 * @param {name} name - The name for the field registration.
 * @param {name} placeholder - The name for the field label.
 * @param {errors} errors - React Hook Form errors object.
 */
function TushopDropDown({
  options = [],
  value,
  onChange,
  disabled,
  register = null,
  name = "",
  error = null,
  wrapped = false,
  placeholder
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Register field if register exists
  const registerProps = register && name ? register(name) : {};

  // Function to handle selection
  const handleSelect = (option) => {
    if (onChange) onChange(option.key);

    setIsOpen(false);
  };

  // Click outside to close the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={`relative text-[14px] w-full ${disabled ? "opacity-50" : ""}`}
      ref={dropdownRef}
    >
      <div
        onClick={() => (disabled ? null : setIsOpen(!isOpen))}
        className={`cursor-pointer bg-transparent ${wrapped ? "border rounded-xl px-2" : "border-b"} ${
          error ? "border-red-500" : isOpen ? "border-[#EF7B22]" : "border-gray-400"
        } py-2 text-[#404040] flex justify-between items-center transition-colors duration-300`}
        {...registerProps} // if register exists, this will apply onBlur, ref etc.
      >
        <span className="whitespace-nowrap">
          {value?.label || placeholder || "Select an option"}
        </span>
        <KeyboardArrowDown />
      </div>

      {isOpen && (
        <ul className="absolute w-full bg-white border border-gray-300 shadow-md rounded-md mt-2 z-10 max-h-[200px] overflow-y-auto">
          {options.map((option) => (
            <li
              key={option.key}
              onClick={() => handleSelect(option)}
              className={`px-4 py-2 cursor-pointer ${
                disabled ? "" : "hover:bg-[#EF7B22] hover:text-white"
              } transition`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}

      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
}

export default TushopDropDown;
