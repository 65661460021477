import React from "react";
import TushopInput from "src/components/forms/tushopInput";

function AddPartnerDeliveryFees({ deliveryFees, onUpdate, disabled, formData }) {
  return (
    <div className="w-full flex flex-col  gap-4 justify-start ">
      {deliveryFees.map((fee, index) => (
        <div key={index} className="flex gap-4 w-full">
          <div className="flex items-center gap-[8px] text-[14px]">
            <span>Up to a distance of</span>
            <span className="w-[70px]">
              <TushopInput
                name="radius"
                suffix="km"
                placeholder=""
                disabled={disabled}
                value={fee.distance || ""}
                onChange={(e) => onUpdate(index, "distance", e.target.value)}
                className="py-0"
              />
            </span>
            <span>apply a delivery fee of</span>
            <span className="w-[70px]">
              <TushopInput
                type="number"
                prefix="Ksh"
                placeholder=""
                value={fee.fee || ""}
                onChange={(e) => onUpdate(index, "fee", e.target.value)}
              />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AddPartnerDeliveryFees;
