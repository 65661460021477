import axios from "axios";

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Request Interceptor: Attach token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Or use a context/store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    const contentType = response.headers["content-type"];
    if (contentType?.includes("text/csv")) return response;

    return response.data?.result?.data ?? response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // TODO:: Handle unauthorized (e.g., logout user)
      console.log("Unauthorized, logging out...");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
