import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { partnerService } from "../service/partnerService";
import { partnerForm, partnerFormSchema } from "../model/partnerForm";
import { partnerTransformer } from "../model/transformer";
import { resetDeliverySection } from "../utils/formUtils";
import usePopupStore from "src/state/popupStore";
import { showLoader, hideLoader } from "../../../state/useLoaderStore";
import PartnerForm from "../form";
import useAssociationStore from "../state/associationState";
import usePageHeaderStore from "src/state/pageHeaderState";

function EditPartner() {
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const { openPopup } = usePopupStore();
  const { setBrands, setProducts, resetAssociationStore } = useAssociationStore();

  const [loadingPartnerDetails, setLoadingPartnerDetails] = useState(true);

  const { setTitle, hideSearchField } = usePageHeaderStore();

  useEffect(() => {
    setTitle("Update Partner");
    hideSearchField();
    return () => {
      resetAssociationStore();
    };
  }, []);

  const methods = useForm({
    resolver: yupResolver(partnerFormSchema),
    defaultValues: partnerForm // fallback default while loading
  });

  useEffect(() => {
    return () => {
      resetAssociationStore();
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = methods;

  const deliveryByPartner = watch("deliveryData.deliveredByPartner");
  const disabled = !deliveryByPartner;

  // Fetch the partner details on mount
  const getPartnerDetails = async () => {
    try {
      showLoader();
      const response = await partnerService.getPartner(partnerId);

      const brands = response.brandIds;
      const productVariants = response.productVariantIds;

      const brandIds = response.brandIds.map((brandId) => brandId.brandId);
      const productVariantIds = response.productVariantIds.map(
        (productVariantId) => productVariantId.productVariantId
      );

      const mergedData = { ...partnerForm, ...response };
      mergedData.brandIds = brandIds;
      mergedData.productVariantIds = productVariantIds;
      if (mergedData.deliveryData.deliveryFees.length < 5) {
        mergedData.deliveryData.deliveryFees.push(
          ...Array(5 - mergedData.deliveryData.deliveryFees.length).fill({
            distance: null,
            fee: null
          })
        );
      }
      setBrands(brands);
      setProducts(productVariants);

      reset(mergedData);
    } catch (error) {
      openPopup({
        title: `Unable to get partner`,
        message: error?.message ?? "Something went wrong!"
      });
    } finally {
      hideLoader();
      setLoadingPartnerDetails(false);
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, [partnerId]);

  useEffect(() => {
    if (!deliveryByPartner) {
      const newValues = resetDeliverySection();
      setValue("deliveryData", newValues);
    }
  }, [deliveryByPartner, setValue]);

  const onSubmit = async (data) => {
    showLoader();
    try {
      const transformedData = partnerTransformer(data);
      await partnerService.updatePartner(transformedData);

      if (window.history.length > 2) {
        navigate(-1);
      } else {
        navigate("/dashboard/partners/");
      }
    } catch (error) {
      const operation = data.publish ? "Publish" : "Save";
      openPopup({
        title: `Unable to ${operation} partner`,
        message: error.message
      });
    } finally {
      hideLoader();
    }
  };

  if (loadingPartnerDetails) {
    return <div className="flex justify-center items-center h-64"></div>;
  }

  return (
    <PartnerForm
      formData={getValues()}
      setValue={setValue}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      deliveryByPartner={deliveryByPartner}
      methods={methods}
      errors={errors}
      register={register}
      control={control}
      disabled={disabled}
    />
  );
}

export default EditPartner;
