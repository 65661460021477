import React from "react";

/**
 * @param {name} name - The name of the switch button.
 * @param {checked} checked - Whether the switch button is checked.
 * @param {onChange} onChange - The function to be called when the switch button is toggled.
 * @returns A switch button with the specified name, checked status, and onChange function.
 */
function TushopSwitchButton({ name, checked, onChange }) {
  return (
    <label className="relative w-[45px] h-[26px] flex items-center cursor-pointer">
      {/* Hidden Radio Input */}
      <input
        type="checkbox" // Changed to checkbox to allow toggling
        name={name}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />

      {/* Toggle Track */}
      <span
        className={`block w-full h-full rounded-full transition-colors duration-300 
        ${checked ? "bg-[#F2F2F2] border border-[#D9D9D9]" : "bg-[#F2F2F2] border border-[#D9D9D9]"} shadow-inner`}
      ></span>

      {/* Toggle Knob */}
      <span
        className={`absolute left-[4px] top-[4px] right-[4px] w-[18px] h-[18px] rounded-full shadow-md transition-all duration-300 
        ${checked ? "translate-x-[19px] bg-[#EF7B22]" : "bg-[#8C8C8C]"}`}
      ></span>
    </label>
  );
}

export default TushopSwitchButton;
