import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import UploadPlaceholder from "./assets/upload_placeholder.svg";
import { uploadFile, deleteFile } from "src/utils/firebaseUtil";
import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "@mui/icons-material";

function TushopImageUploader({
  label = "Drag & Drop or Click to Upload",
  className = "w-[200px] h-[200px]",
  disabled = false,
  multiple = false,
  firebasePath = "uploads",
  onChange = () => {},
  value = null
}) {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [firebaseUrls, setFirebaseUrls] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [deletingIndexes, setDeletingIndexes] = useState([]);

  // ✅ Initialize from value when it changes
  useEffect(() => {
    if (!value) return;

    const urlsArray = multiple
      ? value.map((url) => ({
          downloadUrl: url,
          firebasePath: "" // optional, if you need the path later (from backend)
        }))
      : [
          {
            downloadUrl: value,
            firebasePath: "" // optional
          }
        ];

    setFirebaseUrls(urlsArray);
    setFiles([]); // clear local files
    setPreviewUrls([]); // clear previews
  }, [value, multiple]);

  // ✅ Handle drop action
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (disabled || acceptedFiles.length === 0) return;

      setIsUploading(true);

      if (!multiple && firebaseUrls.length > 0) {
        const previousFilePath = firebaseUrls[0].firebasePath;
        if (previousFilePath) {
          await deleteFile(previousFilePath);
        }
        setFirebaseUrls([]);
        setFiles([]);
      }

      const newPreviewUrls = acceptedFiles.map((file) => ({
        previewUrl: URL.createObjectURL(file),
        fileName: file.name
      }));

      setPreviewUrls((prev) => (multiple ? [...prev, ...newPreviewUrls] : newPreviewUrls));
      setFiles((prev) => (multiple ? [...prev, ...acceptedFiles] : acceptedFiles));

      const newUploads = await Promise.all(
        acceptedFiles.map(async (file) => {
          const uploadResult = await uploadFile(file, firebasePath);

          if (!uploadResult.error) {
            return {
              downloadUrl: uploadResult.downloadUrl,
              firebasePath: `${firebasePath}/${Date.now()}-${file.name}`
            };
          } else {
            console.error(uploadResult.displayMessage);
            return null;
          }
        })
      );

      const validUploads = newUploads.filter((item) => item !== null);

      const updatedFirebaseUrls = multiple ? [...firebaseUrls, ...validUploads] : validUploads;

      setFirebaseUrls(updatedFirebaseUrls);
      setPreviewUrls([]);
      setIsUploading(false);

      onChange(
        multiple
          ? updatedFirebaseUrls.map((upload) => upload.downloadUrl)
          : (updatedFirebaseUrls[0]?.downloadUrl ?? "")
      );
    },
    [disabled, multiple, firebaseUrls, firebasePath, onChange]
  );

  // ✅ Remove image handler with deleting state
  const removeImage = async (index) => {
    setDeletingIndexes((prev) => [...prev, index]);
    const fileInfo = firebaseUrls[index];

    if (fileInfo?.firebasePath) {
      try {
        await deleteFile(fileInfo.firebasePath);
      } catch (error) {
        console.error("Error deleting image:", error.message);
      }
    }

    const updatedUrls = [...firebaseUrls];
    updatedUrls.splice(index, 1);
    setFirebaseUrls(updatedUrls);

    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    setDeletingIndexes((prev) => prev.filter((i) => i !== index));

    onChange(
      multiple
        ? updatedUrls.map((upload) => upload.downloadUrl)
        : (updatedUrls[0]?.downloadUrl ?? "")
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple,
    disabled
  });

  const displayImages = previewUrls.length > 0 ? previewUrls : firebaseUrls;
  const hasImage = displayImages.length > 0;

  return (
    <div
      {...getRootProps()}
      className={`
        ${!hasImage ? "border-2 border-dashed border-gray-300" : "border-none"}
        bg-[#F2F2F2] rounded-lg relative transition-all duration-300 ease-in-out
        ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
        ${className} p-4 overflow-auto
      `}
      style={{ maxHeight: "400px" }}
    >
      <input {...getInputProps()} />

      {/* Empty State */}
      {!hasImage && (
        <div className="flex flex-col justify-center items-center text-center px-4">
          <img
            src={UploadPlaceholder}
            alt="Upload Placeholder"
            className="w-12 h-12 text-gray-400"
          />
          <p className="text-gray-500 text-sm mt-2">{label}</p>
          <div className="flex justify-center items-center px-[8px] py-[16px] border border-[#232F3E] text-[#232F3E] mt-[16px] rounded-[12px] text-[12px] font-bold">
            <p>Choose File</p>
          </div>
        </div>
      )}

      {/* Multiple Image Mode */}
      {multiple && hasImage && (
        <div className="flex flex-wrap gap-4">
          {displayImages.map((img, index) => {
            const isDeleting = deletingIndexes.includes(index);
            const isPreviewing = !!img.previewUrl;

            return (
              <div
                key={index}
                className="relative w-[100px] h-[100px] rounded overflow-hidden flex justify-center items-center bg-white"
              >
                <img
                  src={img.downloadUrl || img.previewUrl}
                  alt={`Uploaded ${index}`}
                  className="w-full h-full object-cover"
                />

                {!disabled && firebaseUrls.length > 0 && !isDeleting && (
                  <div className="p-[1px] border border-white absolute top-1 right-1 rounded-full">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeImage(index);
                      }}
                      className="bg-white text-black rounded-full shadow z-20 w-[20px] h-[20px] flex items-center justify-center opacity-90"
                    >
                      <Close className="text-sm" />
                    </button>
                  </div>
                )}

                {(isPreviewing || isDeleting) && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
                    <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* Single Image Mode */}
      {!multiple && hasImage && (
        <div className="absolute inset-0 w-full h-full">
          <img
            src={displayImages[0]?.downloadUrl || displayImages[0]?.previewUrl}
            alt="Uploaded"
            className="w-full h-full object-cover rounded-lg"
          />

          {(previewUrls.length > 0 || deletingIndexes.includes(0)) && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center rounded-lg z-20">
              <CircularProgress size={40} style={{ color: "#FFFFFF" }} />
            </div>
          )}

          {!disabled && !deletingIndexes.includes(0) && (
            <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white py-2 text-center rounded-b-lg text-[14px] font-medium z-10">
              Change
            </div>
          )}
        </div>
      )}

      {isDragActive && (
        <div className="absolute inset-0 bg-black bg-opacity-25 flex justify-center items-center rounded-lg z-30">
          <p className="text-white font-semibold">Drop files here...</p>
        </div>
      )}
    </div>
  );
}

export default TushopImageUploader;
