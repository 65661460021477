export const partnerTransformer = (partner) => {
  return {
    ...partner,
    deliveryData: {
      ...partner.deliveryData,
      deliveryFees: partner.deliveryData.deliveryFees.filter(
        (fee) => fee.distance !== null && fee.fee !== null
      )
    }
  };
};
