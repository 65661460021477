import React, { useRef, useState } from "react";

/**
 * @param {placeholder} placeholder - The placeholder text for the input field.
 * @param {name} name - The name of the input field.
 * @param {value} value - The initial value of the input field.
 * @param {onChange} onChange - The function to be called when the input field value changes.
 * @param {className} className - The class name for the input field.
 * @param {prefix} prefix - The prefix text or icon for the input field.
 * @param {suffix} suffix - The suffix text or icon for the input field.
 * @returns An input field with the specified placeholder, name, value, onChange function, className, prefix, and suffix.
 */
function TushopInput({
  placeholder,
  name,
  value,
  field,
  onChange,
  className,
  prefix = null, // Text or Icon before input
  suffix = null, // Text or Icon after input,
  register = null,
  errors = null,
  type = "text",
  disabled = false
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const registerProps = register ? register(name) : {};

  const getNestedError = (errors, name) => {
    if (!errors || !name) return null;

    const keys = name.split(".");
    let error = errors;

    for (let key of keys) {
      if (!error[key]) return null;
      error = error[key];
    }

    return error;
  };
  const errorObject = getNestedError(errors, name);
  const errorMessage = errorObject?.message;

  return (
    <span className="flex flex-col">
      <div
        className={`flex items-center w-full border-b ${isFocused ? "border-[#EF7B22]" : "border-gray-400"} ${disabled ? "opacity-50" : ""} transition-colors duration-300 `}
      >
        {/* Prefix (Optional) */}
        {prefix && (
          <span className="text-[#BFBFBF] text-[14px] mr-2">
            {typeof prefix === "string" ? prefix : <span>{prefix}</span>}
          </span>
        )}
        {/* Input Field */}
        {type === "text" || type === "number" ? (
          <input
            defaultValue={value}
            disabled={disabled}
            type={type}
            ref={inputRef}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={`bg-transparent w-full py-2 outline-none text-[14px] font-medium ${className}`}
            {...registerProps}
          />
        ) : (
          <textarea
            defaultValue={value}
            ref={inputRef}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className="resize bg-transparent w-full py-2 outline-none text-[14px] font-medium"
            disabled={disabled}
            rows="3"
            type={type}
            {...registerProps}
          ></textarea>
        )}
        {/* Suffix (Optional) */}
        {suffix && (
          <span className="text-[#BFBFBF] text-[14px] ml-2">
            {typeof suffix === "string" ? suffix : <span>{suffix}</span>}
          </span>
        )}
      </div>

      {errorMessage && <p className="text-red-500 text-xs mt-1">{errorMessage}</p>}
    </span>
  );
}

export default TushopInput;
