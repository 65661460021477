import { updateFcmToken } from "src/api/users";
import {
  getDownloadURL,
  getToken,
  messaging,
  ref,
  storage,
  uploadBytesResumable,
  deleteObject
} from "../firebase/config";
import { urlIsVideoOrImageUpload } from "./utils";

//Request Notification
export const requestPermissionNotification = async (setTokenFound, setNotificationPermission) => {
  try {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID
      });

      // call api to update user token
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        await updateFcmToken({ userId: user.userId, fcmToken: token });
        setTokenFound(true);
        localStorage.setItem("notificationEnable", JSON.stringify(true));
      }
    } else if (permission === "denied") {
      console.log("Notification permission denied");
      localStorage.setItem("notificationEnable", JSON.stringify(false));
    } else {
      console.log("Unable to get notification permission");
      localStorage.setItem("notificationEnable", JSON.stringify(false));
    }
  } catch (error) {
    console.log("Notification permission request error", error?.message);
  }
};

const isStorageInitialized = (storage) => storage !== undefined;

//Upload File
export const uploadFile = async (file, filePath) => {
  if (!isStorageInitialized(storage))
    return {
      downloadUrl: null,
      error: true,
      displayMessage: "Firebase Storage is not initialized"
    };

  //File type check
  if (!urlIsVideoOrImageUpload(file))
    return { downloadUrl: null, error: true, displayMessage: "Please upload correct file format" };

  const defaultSize =
    urlIsVideoOrImageUpload(file) === 1
      ? process.env.REACT_APP_FILESIZE_LIMIT
      : process.env.REACT_APP_FILESIZE_VIDEO_LIMIT;

  //File size check
  if (defaultSize && file?.size > parseInt(defaultSize))
    return {
      downloadUrl: null,
      error: true,
      displayMessage:
        urlIsVideoOrImageUpload(file) === 1
          ? "Please upload image less than 150kb?"
          : "Please upload video less than 5Mb?"
    };

  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `${filePath}/${Date.now()}-${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        reject({
          downloadUrl: null,
          error: true,
          displayMessage: error?.message ?? "Upload failed. Try again later"
        });
      },
      async () => {
        try {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({
            downloadUrl,
            error: false,
            displayMessage: "Upload successfull"
          });
        } catch (error) {
          reject({
            downloadUrl: null,
            error: true,
            displayMessage: error?.message ?? "Upload failed. Try again later"
          });
        }
      }
    );
  });
};

export const deleteFile = async (filePath) => {
  if (!isStorageInitialized(storage)) {
    return {
      error: true,
      displayMessage: "Firebase Storage is not initialized"
    };
  }

  try {
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
    console.log("File deleted successfully");
    return {
      error: false,
      displayMessage: "File deleted successfully"
    };
  } catch (error) {
    console.error("Error deleting file:", error.message);
    return {
      error: true,
      displayMessage: error?.message ?? "Delete failed. Try again later"
    };
  }
};
